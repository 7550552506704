import EditableContainer, {
  EditableContainerProps
} from "aem/ui/authoring/EditableContainer";
import RHLink, { RHLinkProps } from "component-rh-link";
import React, { MouseEvent, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { getLocaleFromPath, convertPathLocale } from "utils/intlUtils";
import { processEnvServer } from "hooks/useSsrHooks";
import { getClientOrigin } from "utils/getClientOrigin";
import { addPgTermFallback } from "utils/pgTerm";
import OpenTableReservationDialog from "@RHCommerceDev/dialog-opentable-reservation";
export type LinkProps = RHLinkProps & {
  linkType?: "path" | "modal" | "url";
  restref?: "";
  linkADADescription?: "";
  overrideLocalization?: boolean;
  modalName?: string;
};

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  linkType,
  restref,
  onClick,
  to,
  modalName,
  overrideLocalization,
  ...rest
}) => {
  const willLinkToAnchor = !!(linkType === "url" && href && /#/.test(href));
  const [showReservationDialog, setShowReservationDialog] =
    useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>, restId) => {
    onClick?.(event);
    if (linkType === "modal" && modalName === "openTableReservation")
      setShowReservationDialog(true);
    if (!event.isDefaultPrevented() && willLinkToAnchor) {
      event.preventDefault();

      const anchorId = href?.split("#")[1];

      const anchorEl = document.getElementById(anchorId ?? "");

      anchorEl?.scrollIntoView({ behavior: "smooth" });
    } else if (
      linkType === "url" &&
      !event.isDefaultPrevented() &&
      href?.includes("/catalog/category/products.jsp")
    ) {
      event.preventDefault();
      const completeHref = addPgTermFallback(href);
      window.location.href = completeHref;
    }
  };
  const location = useLocation();
  let currentLocale = getLocaleFromPath(location.pathname);
  if (!currentLocale || !currentLocale.locale || currentLocale.locale === "") {
    currentLocale = getLocaleFromPath(location.pathname);
  }
  let localizedTo: undefined | string = undefined;
  let localizedHref: undefined | string = undefined;
  if (to) {
    const toLocale = getLocaleFromPath(to);
    const contentPathRegex = /\/content\//;
    const isToContentPath = to.search(contentPathRegex) !== -1;
    if (overrideLocalization) {
      localizedTo = to;
    } else {
      if (isToContentPath) {
        localizedTo = convertPathLocale(
          to,
          toLocale?.localeRegex,
          currentLocale && currentLocale.locale && currentLocale.locale !== ""
            ? currentLocale.locale
            : "/us/en/"
        ).replace("//", "/");
      } else {
        let hostName = processEnvServer
          ? getClientOrigin()
          : window?.location?.host;
        const prefix = hostName?.includes("rhguesthouse")
          ? ""
          : currentLocale && currentLocale.locale && currentLocale.locale !== ""
          ? currentLocale.locale
          : "/us/en/";
        localizedTo = (prefix + to).replace("//", "/");
      }
    }
  }
  if (href) {
    const hrefLocale = getLocaleFromPath(href);
    localizedHref =
      overrideLocalization || !currentLocale || !currentLocale.locale
        ? href
        : convertPathLocale(
            href,
            hrefLocale?.localeRegex,
            currentLocale.locale
          );
  }

  const handleClose = useCallback(() => {
    const timer = setTimeout(() => {
      setShowReservationDialog(false);
      clearTimeout(timer);
    }, 0);
  }, []);

  return (
    <RHLink
      id={"component-link"}
      {...rest}
      style={rest} // TODO: make it right ( ehttp://local.rhnonprod.com/xpose style on the model )
      to={linkType === "path" ? localizedTo : undefined}
      href={linkType === "url" ? localizedHref : undefined}
      onClick={event => handleClick(event, restref)}
      bypassLocalization={true}
    >
      {children}
      {linkType === "modal"
        ? modalName === "openTableReservation" && (
            <OpenTableReservationDialog
              open={showReservationDialog}
              onClose={handleClose}
              restref={parseInt(restref)}
            ></OpenTableReservationDialog>
          )
        : null}
    </RHLink>
  );
};

Link.defaultProps = {};

export type AEMLinkProps = LinkProps & EditableContainerProps;

export const AEMLink: React.FC<AEMLinkProps> = ({
  children,
  isInEditor,
  ...rest
}) => {
  if (isInEditor) {
    return (
      <EditableContainer {...rest}>
        <Link {...rest}>{children}</Link>
      </EditableContainer>
    );
  }

  return <Link {...rest}>{children}</Link>;
};

AEMLink.defaultProps = {};

export default AEMLink;
